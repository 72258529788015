import { reactive } from "vue";
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

import { auth } from "../utils/firebase.js";
import UserLoginView from "../views/UserLoginView.vue";
import UserSettingsView from "../views/UserSettingsView.vue";
import AvatarLessonView from "../views/AvatarLessonView.vue";
import UserRegisterStep1View from "../views/UserRegisterStep1View.vue";
import UserRegisterStep2View from "../views/UserRegisterStep2View.vue";
import UserRegisterStep3View from "../views/UserRegisterStep3View.vue";
import UserRegisterStep4View from "../views/UserRegisterStep4View.vue";
import UserRegisterStep5View from "../views/UserRegisterStep5View.vue";
import UserRegisterStep6View from "../views/UserRegisterStep6View.vue";
import UserRegisterStep7View from "../views/UserRegisterStep7View.vue";
import UserRegisterLetsStartView from "../views/UserRegisterLetsStartView.vue";
import UserPasswordResetView from "../views/UserPasswordResetView.vue";
import LessonTabsView from "../views/LessonTabsView.vue";
import TeachersListView from "../views/TeachersListView.vue";

// import Dashboard from "../components/Dashboard.vue";
const routeHistory = reactive([]);

const routes = [
  {
    path: "/",
    name: "AvatarLesson",
    component: AvatarLessonView,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "UserLogin",
    component: UserLoginView,
  },
  {
    path: "/settings",
    name: "UserSettings",
    component: UserSettingsView,
  },
  {
    path: "/register",
    name: "UserRegister1",
    component: UserRegisterStep1View,
  },
  {
    path: "/register/step-2",
    name: "UserRegister2",
    component: UserRegisterStep2View,
  },
  {
    path: "/register/step-3",
    name: "UserRegister3",
    component: UserRegisterStep3View,
  },
  {
    path: "/register/step-4",
    name: "UserRegister4",
    component: UserRegisterStep4View,
  },
  {
    path: "/register/step-5",
    name: "UserRegister5",
    component: UserRegisterStep5View,
  },
  {
    path: "/register/step-6",
    name: "UserRegister6",
    component: UserRegisterStep6View,
  },
  {
    path: "/register/step-7",
    name: "UserRegister7",
    component: UserRegisterStep7View,
  },
  {
    path: "/register/lets-start",
    name: "UserRegisterLetsStart",
    component: UserRegisterLetsStartView,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: UserPasswordResetView,
  },
  {
    path: "/lessons/:lessonId?",
    name: "LessonsTabs",
    component: LessonTabsView,
    props: (route) => {
      const lessonId = route.params.lessonId || null;
      return {
        lessonId,
      };
    },
  },
  {
    path: "/teachers/:mode?/:lessonId?",
    name: "TeachersList",
    component: TeachersListView,
    props: (route) => {
      const lessonId = route.params.lessonId || null;
      const mode = route.params.mode || null;

      return {
        lessonId,
        mode,
      };
    },
  },
  {
    path: "/lesson",
    name: "AvatarLesson",
    component: AvatarLessonView,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history:
    process.env.VUE_APP_ROUTER_MODE === "hash"
      ? createWebHashHistory()
      : createWebHistory(),

  routes,
});

// Navigation Guard for Auth Routes
router.beforeEach((to, from, next) => {
  auth.onAuthStateChanged((user) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresAuth && !user) {
      next("/login");
    } else if (to.path === "/login" && user) {
      next("/");
    } else {
      next();
    }
  });
});

// Track each visited route in the history
router.afterEach((to) => {
  routeHistory.push(to.fullPath);
});

// Function to find if a specific route exists in history
router.findRouteInHistory = function (routePath) {
  return routeHistory.includes(routePath);
};

// Function to pop to a specific route if it exists
router.popToRoute = function (routePath) {
  const routeExists = this.findRouteInHistory(routePath);
  if (routeExists) {
    const targetIndex = routeHistory.lastIndexOf(routePath);
    const stepsBack = routeHistory.length - 1 - targetIndex;
    this.go(-stepsBack);
    return true; // Route found and navigated
  } else {
    return false; // Route not found
  }
};

export default router;
