<template>
  <div>
    <h2>{{ $t("teachers.title") }}</h2>
    <div ref="teachersContainer" class="teachers-container" v-if="isDataLoaded">
      <TeacherBox
        v-for="teacher in teachers"
        :key="teacher.id"
        :teacher="teacher"
        @show-teacher-details="handleShowDetails"
        :chosen-teacher-id="chosenTeacherId"
      >
      </TeacherBox>
    </div>
    <LoaderSpinner v-else> </LoaderSpinner>
    <transition name="fade">
      <TeacherDetailsPopup
        v-if="selectedTeacher"
        :teacher="selectedTeacher"
        @close="closePopup"
        @select-teacher="selectTeacher"
      />
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import LoaderSpinner from "../components/LoaderSpinner.vue";
import TeacherDetailsPopup from "../components/TeacherDetailsPopup.vue";
import TeacherBox from "../components/TeacherBox.vue";
import teacherService from "../services/teacherService";
import userService from "@/services/userService";

const router = useRouter();
const teachers = ref([]);
const isDataLoaded = ref(false);
const selectedTeacher = ref(null);
const chosenTeacherId = ref(null);

const props = defineProps({
  lessonId: {
    type: String,
    default: null,
  },
  mode: {
    type: String,
    default: "default",
  },
});

onMounted(async () => {
  try {
    const res = await teacherService.listTeachers();
    teachers.value = res.data.teachers;
    isDataLoaded.value = true;
    const userData = await userService.getUserData();
    if (userData.teacher_id) {
      chosenTeacherId.value = userData.teacher_id;
    }
  } catch (error) {
    console.error(error.message);
  }
});

function handleShowDetails(teacher) {
  selectedTeacher.value = teacher;
}

function closePopup() {
  selectedTeacher.value = null;
}

async function selectTeacher() {
  let teacherId = selectedTeacher.value.id;
  let res = await userService.selectTeacher(teacherId);
  chosenTeacherId.value = res.user.teacher_id;
  selectedTeacher.value = null;
  if (props.mode === "lesson") {
    router.push(`/lessons/${props.lessonId}`);
  } else {
    setTimeout(() => {
      router.push(`/lessons/${props.lessonId}`);
    }, 1500);
  }
}
</script>

<style>
.teachers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.teacher-card {
  width: 100%;
  display: flex;
}
</style>
