<template>
  <div id="app">
    <AppAlert />
    <header>
      <!-- Navigation Bar -->
      <nav v-if="shouldShowNav">
        <div class="nav-inline">
          <div class="logo-container">
            <router-link to="/" v-if="user"
              ><div class="logo-wrapper">
                <img
                  src="assets/images/speakearly.png"
                  alt="Logo"
                  class="logo"
                /></div
            ></router-link>
          </div>
          <div class="nav-links">
            <router-link to="/lessons" v-if="user">{{
              $t("nav.lessons")
            }}</router-link>
            <router-link to="/teachers" v-if="user">{{
              $t("nav.teachers")
            }}</router-link>
            <router-link to="/login" v-if="!user">{{
              $t("nav.login")
            }}</router-link>
            <router-link to="/register" v-if="!user">{{
              $t("nav.register")
            }}</router-link>
          </div>
        </div>
        <div class="dropdown" v-if="user">
          <button class="dropbtn">
            <img
              src="assets/images/userProfileIcon.svg"
              style="height: 30px; width: 30px"
            />
          </button>
          <div class="dropdown-content">
            <a href="/my-lessons">{{ $t("nav.myLessons") }}</a>
            <a href="/settings">{{ $t("nav.settings") }}</a>
            <a href="#" class="logout" @click="handleLogout">{{
              $t("nav.logout")
            }}</a>
          </div>
        </div>
      </nav>
    </header>

    <!-- Main Content Rendered by Vue Router -->
    <main class="main-view-container">
      <router-view class="main-view" />
    </main>

    <footer v-if="isNotMobile">
      <!-- Footer Content -->
      <p>{{ $t("footer.copyright") }}</p>
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { auth } from "./utils/firebase.js";
import { signOut } from "firebase/auth";
import AppAlert from "./components/AppAlert.vue";

const user = ref(null);
const router = useRouter();
const isNotMobile = process.env.VUE_APP_PLATFORM !== "ios";

const route = useRoute();
const shouldShowNav = computed(() => {
  const hiddenRoutes = ["/lesson"]; // Add routes where you want to hide the nav
  return !hiddenRoutes.includes(route.path) && isNotMobile;
});

onMounted(() => {
  auth.onAuthStateChanged((currentUser) => {
    user.value = currentUser;
  });

  document.title = "Speakearly";
});

const handleLogout = async () => {
  try {
    await signOut(auth);
    router.replace("/login");
  } catch (error) {
    console.error("Logout error:", error);
  }
};
</script>

<style>
@import "./styles.css";
</style>

<style scoped>
.dropbtn {
  border: none;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 50px;
}

.logo {
  width: 40px;
}

.logo-wrapper {
  overflow: hidden; /* Hides any part of the image that exceeds the wrapper */
  width: 40px;
  height: 40px;
}

.nav-links {
  display: flex;
  justify-content: center;
  gap: 1rem; /* Adjust the value as needed */
  flex-grow: 1;
}

.nav-inline {
  display: flex;
  width: 100%;
}

.nav-links a {
  margin: 10px;
}

.nav-inline a:hover {
  color: red; /* Hover state */
}

.router-link-active {
  color: red;
}
.router-link-exact-active {
  color: red;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
}

.dropdown-content .logout {
  border-top: 1px solid #f1f1f1;
}

.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
