import { createI18n } from "vue-i18n";

const messages = {
  en: {
    footer: {
      copyright: "© 2024 Speakearly",
    },
    common: {
      continue: "Continue",
      save: "Save",
      pauseAndContinueLater: "Pause and Continue Later",
      back: "Back",
      confirm: "Confirm",
      finish: "Finish",
      saveAndFinish: "Save and Finish",
      deleteProgressAndExit: "Delete Progress and Exit",
      next: "Next",
      previous: "Previous",
      submit: "Submit",
      close: "Close",
      nodatayet: "No data yet.",
    },
    nav: {
      home: "Home",
      lessons: "Lessons",
      teachers: "Teachers",
      login: "Login",
      register: "Register",
      accountSettings: "Account Settings",
      profile: "Profile",
      logout: "Logout",
      myLessons: "My Lessons",
      settings: "Settings",
    },
    login: {
      title: "Login",
      enterEmail: "Enter email",
      password: "Password",
      loginButton: "Login",
      resetPasswordButton: "Reset Password",
      registerButton: "Register",
      resetEmailSent: "Password reset email sent!",
      registrationSuccess: "Registration successful!",
    },
    register: {
      title: "Register",
      name: "Name",
      email: "Email",
      password: "Password",
      city: "City",
      confirmPassword: "Confirm Password",
      registerButton: "Register",
      continue: "Continue",
      optional: "(optional)",
      successMessage: "You have successfully registered!",
      passwordMismatch: "Passwords do not match!",
      welcome: "Enter your email to start personalized AI learning journey.",
      provideNameAndPassword: "Create your secure profile to continue.",
      chooseInterestsTitle: "Choose Your Interests",
      chooseInterestsSubtitle:
        "Select the topics that you are passionate about from the list below.",
      learningFrequencyTitle: "Estimate Your Learning Frequency",
      learningFrequencySubtitle:
        "How often would you like to practice your language skills each week?",
      lessonTypeInterestingTitle: "What's Your Learning Preference?",
      lessonTypeInterestingSubTitle:
        "Pick the lesson type that interests you most.",
      completedTitle: "Registration Complete!",
      completedMessage:
        "Thank you for completing your registration. You are now ready to choose your teacher.",
      letsStart: "Let's Get Started",
      locationAndLanguageTitle: "Location & Language",
      locationAndLanguageSubTitle:
        "Enter Your Country, City, and Native Language",
    },
    settings: {
      title: "Settings",
      userProfile: "Profile",
      languageLevel: "Language Level",
      interests: "Interests",
      learningPreference: "Learning Preference",
    },
    profile: {
      title: "User Profile",
      nameLabel: "Enter your name",
      emailLabel: "Enter new email",
      passwordLabel: "Enter new password",
      updateNameButton: "Update Name",
      changeEmailButton: "Change Email",
      changePasswordButton: "Change Password",
      deleteAccountButton: "Delete Account",
      country: "Country",
      city: "City",
      timezone: "Timezone",
      nativeLanguage: "Native Language",
    },
    resetPassword: {
      title: "Reset Your Password",
      sendButton: "Send Reset Link",
      emailPlaceholder: "Enter your email",
      messageSuccess: "Reset password link sent. Check your email.",
      messageError: "Failed to send password reset email.",
    },
    teacher: {
      select: "Select",
      change: "Change",
      choose: "Choose",
      listen: "Listen",
    },
    teachers: {
      title: "Teachers",
    },
    lessons: {
      title: "Lessons",
      suggested: "Suggested",
      finished: "Finished",
      current: "Current",
      other: "Other",
    },
    lesson: {
      error: "An error occurred",
      startRecording: "Start Recording",
      stopRecording: "Stop Recording",
      toggleTTS: "Toggle Text-To-Speech",
      resetChat: "Reset",
      typeMessage: "Type your message here",
      speakOrTypeMessage: "Speak or type your message",
      areYouSureToStop: "Are you sure you want to stop the lesson?",
      send: "Send",
      devOptions: "Dev Options",
      pasteJSON: "Paste JSON text here",
      import: "Import",
      select: "Select",
      list: "List",
      start: "Start",
      continue: "Continue",
    },
    language: {
      chooseLevel: "Choose Your Language Level",
      chooseLevelSubtitle:
        "Select the level that best describes your proficiency.",
      levels: {
        A1: "Beginner (A1)",
        A2: "Elementary (A2)",
        B1: "Intermediate (B1)",
        B2: "Upper Intermediate (B2)",
        C1: "Advanced (C1)",
        C2: "Proficiency (C2)",
      },
    },
    feedback: {
      enterYourRemarks: "Enter your remarks here...",
      thankYouTitle: "Thank you for your feedback!",
      thankYouSubtitle: "Your responses have been submitted successfully.",
    },
  },
  fr: {
    footer: {
      copyright: "© 2024 Speakearly",
    },
    nav: {
      home: "Accueil",
      login: "Connexion",
      register: "Inscription",
      accountSettings: "Paramètres du Compte",
      profile: "Profil",
      logout: "Déconnexion",
    },
    login: {
      title: "Connexion",
      enterEmail: "Entrez l'email",
      password: "Mot de passe",
      loginButton: "Connexion",
      resetPasswordButton: "Réinitialiser le mot de passe",
      registerButton: "S'inscrire",
      resetEmailSent: "E-mail de réinitialisation du mot de passe envoyé!",
      registrationSuccess: "Inscription réussie!",
    },
    register: {
      title: "S'inscrire",
      email: "E-mail",
      password: "Mot de passe",
      confirmPassword: "Confirmez le mot de passe",
      registerButton: "S'inscrire",
      successMessage: "Vous êtes bien inscrit!",
      passwordMismatch: "Les mots de passe ne correspondent pas!",
    },
    resetPassword: {
      title: "Réinitialisez votre mot de passe",
      sendButton: "Envoyer le lien de réinitialisation",
      emailPlaceholder: "Entrez votre email",
      messageSuccess: "Lien de réinitialisation envoyé. Vérifiez votre email.",
      messageError: "Échec de l’envoi de l’email de réinitialisation.",
    },
    profile: {
      title: "Profil utilisateur",
      nameLabel: "Entrez votre nom",
      emailLabel: "Entrez un nouvel email",
      passwordLabel: "Entrez un nouveau mot de passe",
      updateNameButton: "Mettre à jour le nom",
      changeEmailButton: "Changer l'email",
      changePasswordButton: "Changer le mot de passe",
      deleteAccountButton: "Supprimer le compte",
    },
  },
};

const i18n = createI18n({
  legacy: false, // Use Composition API
  locale: "en", // Set the initial locale
  fallbackLocale: "en", // Set fallback locale
  messages, // Set locale messages
});

export default i18n;
